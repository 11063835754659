<template>
    <div id="goods-add-box">
        <h3>表单信息</h3>
        <el-form ref="form" :model="goodsInfo" label-width="80px" style="width:500px;">
            <el-form-item label="姓名">
                <el-input v-model="biaodanOne.name"></el-input>
            </el-form-item>

            <el-form-item label="手机号">
                <el-input v-model="biaodanOne.phone"></el-input>
            </el-form-item>
            <el-form-item label="病情描述">
                <el-input v-model="biaodanOne.desc"></el-input>
            </el-form-item>

            <el-form-item label="病情描述">
                <div v-for="item, index in qus" :key=index>
                    <div>{{ item.question }}</div>
                    <div>{{ item.answer }}</div>
                </div>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
import { getForm, postJson } from '../tools/tool'

export default {
    name: "GoodsAddView",
    data() {
        return {
            biaodanOne: null,
            qus: null,
        }
    },
    created() {
        let yuyueId = this.$route.query.yuyueId;
        console.log("yuyueId: ", yuyueId);
        if (yuyueId) {
            getForm('/yuyue/one', { yuyue_id: yuyueId })
                .then(data => {
                    this.biaodanOne = data.data
                    this.qus = JSON.parse(data.data.remark)
                    console.log('yuyueOne:', data)
                }).catch(err => {
                    alert(err)
                })
        }
    },
    mounted() { },
    methods: {

    },
}
</script>

<style lang="less" scoped>
.upPicSty {
    width: 50px;
    height: 50px;
}
</style>